import React, { useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { AppContext } from "../components/Context"
import SEO from "../components/SEO"
import Heading from "../components/Heading"
import Filters from "../components/Filters"
import ProductTile from "../components/ProductTile"

import styles from "./collection.module.scss"

const topLevelCollections = [
  "sandals",
  "flats",
  "boots",
  "sneakers",
  "pumps",
  "bags",
  "all",
  "casual",
  "new",
]

const Collection = ({ data, location }) => {
  const appState = useContext(AppContext)

  const {
    productCollection
  } = data

  const [collectionStyles, setCollectionStyles] = useState(productCollection.styles)

  useEffect(() => {
    if (!topLevelCollections.includes(productCollection.handle)) {
      // Unmerge styles for non-top-level collections
      const tempStyles = []
      let hasSweetRevenge = false
      productCollection.styles.forEach(style => {
        style.skus.forEach(sku => {
          let tempStyle = { ...style }
          tempStyle.skus = [sku]
          // Ignore non-$100 gift card skus
          // And only include skus with PLP images
          // And only allow one Sweet Revenge tile
          // And only include skus on a sale page with a sale price
          if (!["250","500","1000"].includes(sku.color) && sku.images.plp2.length > 0 && !(style.handle.includes("sweet-revenge") && hasSweetRevenge) && ((productCollection.handle.includes("sale") && sku.variants[0].compareAtPrice) || (!productCollection.handle.includes("sale"))))
            tempStyles.push(tempStyle)
          if (style.handle.includes("sweet-revenge") && !hasSweetRevenge)
            hasSweetRevenge = true
        })
      })
      setCollectionStyles(tempStyles)
    } else {
      // Only allow one Sweet Revenge tile
      const tempStyles = []
      let hasSweetRevenge = false
      productCollection.styles.forEach(style => {
        if (style.handle.includes("sweet-revenge")) {
          if (!hasSweetRevenge) {
            hasSweetRevenge = true
            tempStyles.push(style)
          }
        } else
          tempStyles.push(style)
      })
      setCollectionStyles(tempStyles)
    }
  }, [productCollection.styles, productCollection.handle])

  // Assign featured_order property to set initial sort order
  useEffect(() => {
    collectionStyles.forEach((style, i) => {
      style.featured_order = i
    })
  }, [collectionStyles])

  const [filteredStyles, setFilteredStyles] = useState(collectionStyles)

  const [filteredColor, setFilteredColor] = useState(null)

  useEffect(() => {
    let criteo_items = []
    productCollection.styles.slice(0, 3).forEach(item => {
      criteo_items.push(item.product_id)
    })
    window.criteo_q = window.criteo_q || []
    window.criteo_q.push(
      { event: "viewList", ecpplugin: "shopify", product: criteo_items}
    )
  })

  const [showButtonToTop, setShowButtonToTop] = useState(false)

  const handleButtonToTopClick = e => {
    if (typeof window !== "undefined")
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
  }

  useEffect(() => {
    if (typeof window !== "undefined")
      document.addEventListener("scroll", handleScroll)
    return () =>
      document.removeEventListener("scroll", handleScroll)
  })

  const handleScroll = e => {
    const scrollTop = window.scrollY
    const docHt = document.body.clientHeight
    // Show Back to Top button if user has scrolled past halfway point
    if (scrollTop > docHt / 2)
      setShowButtonToTop(true)
    else
      setShowButtonToTop(false)
  }

  return (
    <>
      <SEO
        title={productCollection.title}
        description={productCollection.description}
        url={location.pathname}
      />

      <header className={styles.header}>
        <Heading
          tag="h1"
          level="0"
          className={styles.heading}
        >
          {productCollection.title}
        </Heading>
        <Filters
          allItems={collectionStyles}
          items={filteredStyles}
          setItems={setFilteredStyles}
          setFilteredColor={setFilteredColor}
          collectionTitle={productCollection.title}
          location={location}
        />
      </header>
      <ul className={`${styles.grid} ${styles[`desktopGrid${appState.gridView[0]}`]} ${styles[`mobileGrid${appState.gridView[1]}`]}`}>
        {filteredStyles.map((style, i) => (
          <ProductTile
            key={i}
            index={i}
            style={style}
            filteredColor={filteredColor}
            customStyles={styles}
            origin={`collection-${productCollection.handle}`}
            topLevel={topLevelCollections.includes(productCollection.handle)}
            collection={productCollection.title}
          />
        ))}
      </ul>

      {showButtonToTop &&
        <button
          className={styles.btnToTop}
          onClick={handleButtonToTopClick}
          title="Back to Top"
        >
          <span dangerouslySetInnerHTML={{ __html: `&Gt;` }} />
        </button>
      }

      <Helmet>
        {/* Microdata */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org/",
            "@type": "ItemList",
            "name": "${productCollection.title}",
            "description": "${productCollection.description}",
            "numberofItems": ${filteredStyles.length},
            "itemListElement": [
              ${filteredStyles.map((style, i) => {
                return `{
                  "@type": "ListItem",
                  "position": ${i+1},
                  "name": "${style.title}",
                  "url": "https://www.tamaramellon.com${style.url}"
                }`
              })}
            ]
          }
        `}</script>
      </Helmet>
    </>
  )
}

export default Collection

export const query = graphql`
  query CollectionQuery($id: String) {
    productCollection(id: { eq: $id }) {
      id
      handle
      title
      description
      descriptionHtml
      styles {
          ...PLPStyleFragment
#          relatedStyles {
#              ...PLPStyleFragment
#          }
      }
    }
  }
`

