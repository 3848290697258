import React, { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import qs from "qs"

import { AppContext } from "./Context"

import styles from "./filters.module.scss"
import overlayStyles from "./overlay.module.scss"

import { toTitleCase } from "../helpers"

const Filters = ({ allItems, items, setItems, setFilteredColor, collectionTitle, location }) => {
  const appState = useContext(AppContext)

  const isBags = collectionTitle === "Bags"
  const isShopAll = collectionTitle === "Shop All"

  const [filtersOpen, setFiltersOpen] = useState(false)

  const [categoryOptionOpen, setCategoryOptionOpen] = useState(false)
  const [allCategories, setAllCategories] = useState(true)
  const [categories, setCategories] = useState({
    "Boots": false,
    "Pumps": false,
    "Flats": false,
    "Sneakers": false,
    "Sandals": false,
    "Handbags": false,
  })

  const [sizeOptionOpen, setSizeOptionOpen] = useState(false)
  const [sizeOrigin, setSizeOrigin] = useState("US")
  const [allSizes, setAllSizes] = useState(true)
  const [sizes, setSizes] = useState({
    "35": false,
    "35.5": false,
    "36": false,
    "36.5": false,
    "37": false,
    "37.5": false,
    "38": false,
    "38.5": false,
    "39": false,
    "39.5": false,
    "40": false,
    "40.5": false,
    "41": false,
    "41.5": false,
    "42": false,
  })

  const [colorOptionOpen, setColorOptionOpen] = useState(false)
  const [allColors, setAllColors] = useState(true)
  const [colors, setColors] = useState({
    "Black": false,
    "White": false,
    "Grey": false,
    "Blue": false,
    "Purple": false,
    "Green": false,
    "Brown": false,
    "Neutrals": false,
    "Red": false,
    "Orange": false,
    "Yellow": false,
    "Pink": false,
    "Animal": false,
    "Metallic": false,
    "Multi": false,
  })

  const [materialOptionOpen, setMaterialOptionOpen] = useState(false)
  const [allMaterials, setAllMaterials] = useState(true)
  const [materials, setMaterials] = useState({
    "Leather": false,
    "Suede": false,
    "Velvet": false,
    "Eel": false,
    "Fabric": false,
  })

  const [heelHeightOptionOpen, setHeelHeightOptionOpen] = useState(false)
  const [allHeelHeights, setAllHeelHeights] = useState(true)
  const [heelHeights, setHeelHeights] = useState({
    "Flat 0 – 1 inch": false,
    "Low 1 – 2 inches": false,
    "Medium 2 – 3 inches": false,
    "High 3 – 4+ inches": false,
  })

  const [sortOpen, setSortOpen] = useState(false)
  const [sort, setSort] = useState("Featured")
  const sortOptions = [
    "Featured",
    "Newest",
    // "Best selling",
    "Price low to high",
    "Price high to low",
  ]

  const [availableCategories, setAvailableCategories] = useState(new Set())
  const [availableSizes, setAvailableSizes] = useState(new Set())
  const [availableColors, setAvailableColors] = useState(new Set())
  const [availableMaterials, setAvailableMaterials] = useState(new Set())
  const [availableHeelHeights, setAvailableHeelHeights] = useState(new Set())

  const resetOptions = options => {
    let temp = {}
    Object.keys(options).forEach(key => temp[key] = false)
    return temp
  }

  const resetFilters = () => {
    // TODO: Is there a better way to do this?
    setCategories({...resetOptions(categories)})
    setAllCategories(true)
    setSizes({...resetOptions(sizes)})
    setAllSizes(true)
    setColors({...resetOptions(colors)})
    setAllColors(true)
    setMaterials({...resetOptions(materials)})
    setAllMaterials(true)
    setHeelHeights({...resetOptions(heelHeights)})
    setAllHeelHeights(true)
  }

  // Apply filters based on query string
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true })
  useEffect(() => {
    if (parsedQuery.categories) {
      const categoriesSelected = parsedQuery.categories.split(",")
      categoriesSelected.forEach(category => categories[category] = true)
    }
    if (parsedQuery.sizes) {
      const sizesSelected = parsedQuery.sizes.split(",")
      sizesSelected.forEach(size => sizes[size] = true)
    }
    if (parsedQuery.colors) {
      const colorsSelected = parsedQuery.colors.split(",")
      colorsSelected.forEach(color => colors[color] = true)
    }
    if (parsedQuery.materials) {
      const materialsSelected = parsedQuery.materials.split(",")
      materialsSelected.forEach(material => materials[material] = true)
    }
    if (parsedQuery.heelHeights) {
      const heelHeightsSelected = parsedQuery.heelHeights.split(",")
      heelHeightsSelected.forEach(heelHeight => heelHeights[heelHeight] = true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  // Update allCategories value based on categories selected
  useEffect(() => {
    let anyCategory = Object.values(categories).some(value => value)
    setAllCategories(!anyCategory)
  }, [categories])

  // Update allSizes value based on sizes selected
  useEffect(() => {
    let anySize = Object.values(sizes).some(value => value)
    setAllSizes(!anySize)
  }, [sizes])

  // Update allColors value based on colors selected
  useEffect(() => {
    let anyColor = Object.values(colors).some(value => value)
    // If a color is selected, set the filtered color to that color
    if (anyColor)
      setFilteredColor(Object.entries(colors).find(c => c[1])[0])
    else
      setFilteredColor(null)
    setAllColors(!anyColor)
  }, [colors, setFilteredColor])

  // Update allMaterials value based on materials selected
  useEffect(() => {
    const anyMaterial = Object.values(materials).some(value => value)
    setAllMaterials(!anyMaterial)
  }, [materials])

  // Update allHeelHeights value based on heel heights selected
  useEffect(() => {
    let anyHeelHeight = Object.values(heelHeights).some(value => value)
    setAllHeelHeights(!anyHeelHeight)
  }, [heelHeights])

  // Filter items by size/color/material/heel height
  useEffect(() => {
    const sortItems = itemsToSort => {
      if (sort === "Featured")
        return itemsToSort.sort((a,b) => a.featured_order - b.featured_order)
      if (sort === "Newest")
        return itemsToSort.sort((a,b) => new Date(b.published_at) - new Date(a.published_at))
      if (sort === "Price low to high")
        return itemsToSort.sort((a,b) => Number(a.skus[0].variants[0].price) - Number(b.skus[0].variants[0].price))
      if (sort === "Price high to low")
        return itemsToSort.sort((a,b) => Number(b.skus[0].variants[0].price) - Number(a.skus[0].variants[0].price))
      return itemsToSort
    }

    // setItems([])
    let filteredItems = (allCategories && allSizes && allColors && allMaterials && allHeelHeights) ?
      allItems
      :
      allItems.filter(item => {
        // Filter by category (if category selected)
        let hasCategory = false
        if (allCategories) hasCategory = true
        else {
          const vtype = item.tags.find(tag => tag.includes("Vtype"))
          if (vtype) {
            const category = toTitleCase(vtype.toLowerCase().replace("vtype=",""))
            hasCategory = categories[category]
          }
        }
        // Filter by size and color (if both selected)
        let hasSize = false
        let hasColor = false
        if (!allSizes && !allColors)
          hasSize = hasColor = item.skus.some(sku => sku.variants.some(v => sizes[v.size] && v.quantity > 0 && colors[sku.colorFamily]))
        else {
          // Filter by size (if size selected)
          if (allSizes) hasSize = true
          else
            hasSize = item.skus.some(sku => sku.variants.some(v => sizes[v.size] && v.quantity > 0))
          // Filter by color (if color selected)
          if (allColors) hasColor = true
          else
            hasColor = item.skus.some(sku => colors[sku.colorFamily])
        }
        // Filter by material
        let hasMaterial = false
        if (allMaterials) hasMaterial = true
        else {
          const material = item.skus[0].material
          hasMaterial = ((materials["Leather"] && ["Vitello","Nappa","Naplack","Canguro","Nappa/Snake Welt","Vegetable Calf","Patent","Haircalf","Python Print","Nappa Laminata","Grainy Calf","Shearling","Calfskin","Lizard Print","Specchio","Capretto","Stretch Nappa"].includes(material)) ||
            (materials["Suede"] && ["Suede","Suede/Glitter Welt","Glitter Suede"].includes(material)) ||
            (materials["Velvet"] && ["Velvet/Crystal Welt","Velvet/Raso/Crystals","Velvet"].includes(material)) ||
            (materials["Eel"] && ["Eel","Elaphe"].includes(material)) ||
            (materials["Fabric"] && ["Fabric","Mesh/Crystals"].includes(material)))
        }
        // Filter by heel height (if heel height selected)
        let hasHeelHeight = false
        if (allHeelHeights) hasHeelHeight = true
        else {
          const hhNumber = Number(item.heel_height.replace("mm",""))
          hasHeelHeight = ((heelHeights["Flat 0 – 1 inch"] && (hhNumber < 25.4 || isNaN(hhNumber))) ||
            (heelHeights["Low 1 – 2 inches"] && hhNumber > 25.4 && hhNumber < 50.8) ||
            (heelHeights["Medium 2 – 3 inches"] && hhNumber > 50.8 && hhNumber < 76.2) ||
            (heelHeights["High 3 – 4+ inches"] && hhNumber > 76.2))
        }
        return hasCategory && hasSize && hasColor && hasMaterial && hasHeelHeight
      })

    // setTimeout(() => {
      setItems([...sortItems(filteredItems)])
    // }, 100)

    // Heap
    if (window.hasOwnProperty("heap"))
      window.heap.track("Apply Filter")
  }, [categories, allCategories, sizes, allSizes, colors, allColors, materials, allMaterials, heelHeights, allHeelHeights, sort, allItems, setItems])

  // Set which colors, heel heights, materials, and categories are available given all items
  useEffect(() => {

    let availableCategoriesSet = new Set()
    let availableColorsSet = new Set()
    let availableMaterialsSet = new Set()
    let availableHeelHeightsSet = new Set()

    allItems.forEach(item => {
      const vtype = item.tags.find(tag => tag.includes("Vtype"))
      if (vtype) {
        const category = toTitleCase(vtype.toLowerCase().replace("vtype=",""))
        availableCategoriesSet.add(category)
      }

      const material = item.skus[0].material
      if (["Vitello","Nappa","Naplack","Canguro","Nappa/Snake Welt","Vegetable Calf","Patent","Haircalf","Python Print","Nappa Laminata","Grainy Calf","Shearling","Calfskin","Lizard Print","Specchio","Capretto","Stretch Nappa"].includes(material))
        availableMaterialsSet.add("Leather")
      else if (["Suede","Suede/Glitter Welt","Glitter Suede"].includes(material))
        availableMaterialsSet.add("Suede")
      else if (["Velvet/Crystal Welt","Velvet/Raso/Crystals","Velvet"].includes(material))
        availableMaterialsSet.add("Velvet")
      else if (["Eel","Elaphe"].includes(material))
        availableMaterialsSet.add("Eel")
      else if (["Fabric","Mesh/Crystals"].includes(material))
        availableMaterialsSet.add("Fabric")

      const hhNumber = Number(item.heel_height.replace("mm",""))
      if (hhNumber < 25.4 || isNaN(hhNumber))
        availableHeelHeightsSet.add("Flat 0 – 1 inch")
      else if (hhNumber > 25.4 && hhNumber < 50.8)
        availableHeelHeightsSet.add("Low 1 – 2 inches")
      else if (hhNumber > 50.8 && hhNumber < 76.2)
        availableHeelHeightsSet.add("Medium 2 – 3 inches")
      else if (hhNumber > 76.2)
        availableHeelHeightsSet.add("High 3 – 4+ inches")

      item.skus.forEach(sku => {
        availableColorsSet.add(sku.colorFamily)
      })
    })

    setAvailableCategories(availableCategoriesSet)
    setAvailableColors(availableColorsSet)
    setAvailableMaterials(availableMaterialsSet)
    setAvailableHeelHeights(availableHeelHeightsSet)
  }, [allItems])

  // Set which sizes are still available given the filtered items
  useEffect(() => {

    let availableSizesSet = new Set()

    items.forEach(item => {
      item.skus.forEach(sku => {
        sku.variants.forEach(variant => {
          if (variant.quantity > 0)
            availableSizesSet.add(variant.size)
        })
      })
    })

    setAvailableSizes(availableSizesSet)
  }, [items])

  // Close filters if clicked out of
  const handleOutsideFilterClick = e => {
    if ((categoryOptionOpen || sizeOptionOpen || colorOptionOpen || materialOptionOpen || heelHeightOptionOpen || sortOpen) && e.path) {
      const clickedOnFilter = e.path.some(el => {
        return el && el.className && el.className.includes("filter")
      })
      if (!clickedOnFilter) {
        setCategoryOptionOpen(false)
        setSizeOptionOpen(false)
        setColorOptionOpen(false)
        setMaterialOptionOpen(false)
        setHeelHeightOptionOpen(false)
        setSortOpen(false)
      }
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined")
      document.addEventListener("click", handleOutsideFilterClick)
    return () =>
      document.removeEventListener("click", handleOutsideFilterClick)
  })

  // Add filters to query string
  useEffect(() => {
    if (typeof window !== "undefined") {
      let query = ""
      const categoriesSelected = Object.keys(categories).filter(key => categories[key])
      const sizesSelected = Object.keys(sizes).filter(key => sizes[key])
      const colorsSelected = Object.keys(colors).filter(key => colors[key])
      const materialsSelected = Object.keys(materials).filter(key => materials[key])
      const heelHeightsSelected = Object.keys(heelHeights).filter(key => heelHeights[key])
      if (categoriesSelected.length)
        query += (query.length ? "&" : "?") + "categories=" + categoriesSelected.join(",")
      if (sizesSelected.length)
        query += (query.length ? "&" : "?") + "sizes=" + sizesSelected.join(",")
      if (colorsSelected.length)
        query += (query.length ? "&" : "?") + "colors=" + colorsSelected.join(",")
      if (materialsSelected.length)
        query += (query.length ? "&" : "?") + "materials=" + materialsSelected.join(",")
      if (heelHeightsSelected.length)
        query += (query.length ? "&" : "?") + "heelHeights=" + heelHeightsSelected.join(",")
      // console.log(query)
      const newUrl = window.location.origin + window.location.pathname + query
      window.history.replaceState({}, "", newUrl)
    }
  }, [categories, sizes, colors, materials, heelHeights])

  const [isClient, setClient] = useState(false)
  const key = isClient ? "client" : "server"

  useEffect(() => {
    setClient(true)
  }, [])

  return(
    <div className={`${(!allCategories || !allSizes || !allColors || !allMaterials || !allHeelHeights) ? styles.filtersActive : styles.filters}`} key={key}>
      <div className={styles.itemCountContainer}>
        <p className={styles.itemCount}>{items.length} item{items.length !== 1 ? "s" : ""}</p>
        {typeof window !== "undefined" && window.outerWidth < 768 &&
          <div className={styles.gridViewButtons}>
            <button
              className={`${styles.gridViewBtn2} ${(appState.gridView[1] === 2) && styles.active}`}
              onClick={e => appState.set({ gridView: [appState.gridView[0], 2] })}
              aria-label="2-wide Grid"
            />
            <button
              className={`${styles.gridViewBtn1} ${(appState.gridView[1] === 1) && styles.active}`}
              onClick={e => appState.set({ gridView: [appState.gridView[0], 1] })}
              aria-label="1-wide Grid"
            />
          </div>
        }
      </div>
      <div className={`${(filtersOpen) ? styles.optionsOpen : styles.options} ${(sortOpen) ? styles.optionsSortOpen : ""}`}>
        {typeof window !== "undefined" && window.outerWidth >= 768 &&
        <div className={styles.gridViewButtons}>
          <button
            className={`${styles.gridViewBtn4} ${(appState.gridView[0] === 4) && styles.active}`}
            onClick={e => appState.set({ gridView: [4, appState.gridView[1]] })}>
            <span /><span />
          </button>
          <button
            className={`${styles.gridViewBtn3} ${(appState.gridView[0] === 3) && styles.active}`}
            onClick={e => appState.set({ gridView: [3, appState.gridView[1]] })}>
            <span /><span />
          </button>
          <button
            className={`${styles.gridViewBtn2} ${(appState.gridView[0] === 2) && styles.active}`}
            onClick={e => appState.set({ gridView: [2, appState.gridView[1]] })}>
            <span />
          </button>
        </div>
        }
        <button
          className={(sortOpen) ? styles.optionLabelOpen : styles.optionLabel}
          onClick={() => {
            setSortOpen(!sortOpen)
            setCategoryOptionOpen(false)
            setSizeOptionOpen(false)
            setColorOptionOpen(false)
            setMaterialOptionOpen(false)
            setHeelHeightOptionOpen(false)
          }}
        >Sort</button>
        <div className={styles.sortDrawer}>
          <button
            className={`${overlayStyles.closeBtn} ${styles.closeBtn}`}
            onClick={e => setSortOpen(false)}
            title="Close"
          >Close</button>
          <h5 className={styles.heading}>Sort</h5>
          <ul className={styles.optionList}>
            {sortOptions.map((sortOption, i) => (
              <li
                key={i}
                className={styles.radio}
              >
                <input
                  type="radio"
                  name="sort"
                  id={sortOption}
                  checked={sortOption === sort}
                  onChange={e => setSort(sortOption)}
                />
                <label htmlFor={sortOption}>
                  {sortOption}
                </label>
              </li>
            ))}
          </ul>
        </div>
        <button
          className={`${styles.optionLabel} ${styles.filterBtn}`}
          onClick={e => setFiltersOpen(true)}
        >Filter</button>
        <div className={styles.optionsDrawer}>
          <button
            className={`${overlayStyles.closeBtn} ${styles.closeBtn}`}
            onClick={e => setFiltersOpen(false)}
            title="Close"
          >Close</button>
          <h5 className={styles.heading}>Filter</h5>
          <ul className={(isBags) ? styles.optionsDrawerListBags : (isShopAll) ? styles.optionsDrawerListShopAll : styles.optionsDrawerList}>
            {isShopAll &&
              <li>
                <Options
                  label="Style"
                  options={categories}
                  setOptions={setCategories}
                  optionOpen={categoryOptionOpen}
                  setOptionOpen={setCategoryOptionOpen}
                  setOtherOption1Open={setSizeOptionOpen}
                  setOtherOption2Open={setColorOptionOpen}
                  setOtherOption3Open={setMaterialOptionOpen}
                  setOtherOption4Open={setHeelHeightOptionOpen}
                  availableOptions={availableCategories}
                  isCategories={true}
                  {...{
                    setSortOpen,
                  }}
                />
              </li>
            }
            {!isBags &&
              <li>
                <Options
                  label="Size"
                  options={sizes}
                  setOptions={setSizes}
                  optionOpen={sizeOptionOpen}
                  setOptionOpen={setSizeOptionOpen}
                  setOtherOption1Open={setCategoryOptionOpen}
                  setOtherOption2Open={setColorOptionOpen}
                  setOtherOption3Open={setMaterialOptionOpen}
                  setOtherOption4Open={setHeelHeightOptionOpen}
                  availableOptions={availableSizes}
                  {...{
                    setSortOpen,
                    sizeOrigin,
                    setSizeOrigin,
                  }}
                />
              </li>
            }
            <li>
              <Options
                label="Color"
                options={colors}
                setOptions={setColors}
                optionOpen={colorOptionOpen}
                setOptionOpen={setColorOptionOpen}
                setOtherOption1Open={setCategoryOptionOpen}
                setOtherOption2Open={setSizeOptionOpen}
                setOtherOption3Open={setMaterialOptionOpen}
                setOtherOption4Open={setHeelHeightOptionOpen}
                availableOptions={availableColors}
                {...{
                  setSortOpen,
                }}
              />
            </li>
            <li>
              <Options
                label="Material"
                options={materials}
                setOptions={setMaterials}
                optionOpen={materialOptionOpen}
                setOptionOpen={setMaterialOptionOpen}
                setOtherOption1Open={setCategoryOptionOpen}
                setOtherOption2Open={setSizeOptionOpen}
                setOtherOption3Open={setColorOptionOpen}
                setOtherOption4Open={setHeelHeightOptionOpen}
                availableOptions={availableMaterials}
                isMaterials={true}
                {...{
                  setSortOpen,
                }}
              />
            </li>
            {!isBags &&
              <li>
                <Options
                  label="Heel Height"
                  options={heelHeights}
                  setOptions={setHeelHeights}
                  optionOpen={heelHeightOptionOpen}
                  setOptionOpen={setHeelHeightOptionOpen}
                  setOtherOption1Open={setCategoryOptionOpen}
                  setOtherOption2Open={setSizeOptionOpen}
                  setOtherOption3Open={setColorOptionOpen}
                  setOtherOption4Open={setMaterialOptionOpen}
                  availableOptions={availableHeelHeights}
                  isHeelHeights={true}
                  {...{
                    setSortOpen,
                  }}
                />
              </li>
            }
          </ul>
          <div className={styles.buttons}>
            <button
              className={styles.applyBtn}
              onClick={e => setFiltersOpen(false)}
            >
              See Items
            </button>
            <button
              className={styles.clearBtn}
              onClick={resetFilters}
            >
              Clear Filters
            </button>
          </div>
        </div>
      </div>
      {(!allCategories || !allSizes || !allColors || !allMaterials || !allHeelHeights) &&
        <div className={styles.activeFilters}>
          <ul>
            {Object.keys(categories).sort().map((category, i) => (
              <ActiveFilter
                options={categories}
                option={category}
                setOption={setCategories}
                key={i}
              />
            ))}
            {Object.keys(sizes).sort().map((size, i) => (
              <ActiveFilter
                options={sizes}
                option={size}
                setOption={setSizes}
                label="Size"
                key={i}
              />
            ))}
            {Object.keys(colors).map((color, i) => (
              <ActiveFilter
                options={colors}
                option={color}
                setOption={setColors}
                key={i}
              />
            ))}
            {Object.keys(materials).map((material, i) => (
              <ActiveFilter
                options={materials}
                option={material}
                setOption={setMaterials}
                key={i}
              />
            ))}
            {Object.keys(heelHeights).map((heelHeight, i) => (
              <ActiveFilter
                options={heelHeights}
                option={heelHeight}
                setOption={setHeelHeights}
                key={i}
              />
            ))}
            <li className={styles.clearActive}>
              <button
                onClick={resetFilters}
              >
                Clear Filters
              </button>
            </li>
          </ul>
        </div>
      }

      <Helmet
        bodyAttributes={{
          class: (filtersOpen || sortOpen) ? styles.noScroll : ""
        }}
      />
    </div>
  )
}

export default Filters

const ActiveFilter = ({ options, option, setOption, label }) => {

  return(
    <>
      {options[option] &&
        <li>
          <button
            className={styles.activeFilter}
            onClick={e => {
              setOption({
                ...options,
                [option]: false
              })
            }}
          >
            {label} {option}
            <span
              className={styles.removeIcon}
            >
            Remove
          </span>
          </button>
        </li>
      }
    </>
  )
}

const Options = props => {
  const {
    label,
    options,
    setOptions,
    optionOpen,
    setOptionOpen,
    setOtherOption1Open,
    setOtherOption2Open,
    setOtherOption3Open,
    setOtherOption4Open,
    setSortOpen,
    availableOptions,
    sizeOrigin,
    setSizeOrigin,
    isHeelHeights,
    isCategories,
    isMaterials,
  } = props

  const optionKeys = (sizeOrigin) ? Object.keys(options).sort() : Object.keys(options)

  return(
    <>
      <button
        className={(optionOpen) ? styles.optionLabelOpen : styles.optionLabel}
        onClick={() => {
          setOtherOption1Open(false)
          setOtherOption2Open(false)
          setOtherOption3Open(false)
          setOtherOption4Open(false)
          setSortOpen(false)
          setOptionOpen(!optionOpen)
        }}
      >
        {label}
      </button>
      <div
        className={`${(optionOpen) ? styles.optionContainerOpen : styles.optionContainer} ${(isHeelHeights) ? styles.heelHeightContainer : ""} ${(isCategories) ? styles.categoryContainer : ""}  ${(isMaterials) ? styles.materialContainer : ""}`}>
        {sizeOrigin &&
          <div className={styles.sizeOrigins}>
            <button
              className={(sizeOrigin === "EU") ? styles.selected : ""}
              onClick={() => setSizeOrigin("EU")}
            >
              EU
            </button>
            &nbsp;|&nbsp;
            <button
              className={(sizeOrigin === "US") ? styles.selected : ""}
              onClick={() => setSizeOrigin("US")}
            >
              US
            </button>
          </div>
        }
        <ul className={styles.optionList}>
          {optionKeys.map((option, i) => (
            <li
              key={i}
              className={(availableOptions.has(option)) ? styles.option : styles.optionUnavailable}
            >
              <input
                type="checkbox"
                name={option}
                id={option}
                checked={options[option]}
                onChange={e => {
                  setOptions({
                    ...options,
                    [e.target.name]: !options[e.target.name]
                  })
                }}
              />
              <label htmlFor={option}>
                {(sizeOrigin && sizeOrigin === "US") ?
                  Number(option) - 30
                  :
                  option
                }
              </label>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
